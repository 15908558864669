import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { isAuthenticated } from "../helpers";
import Action from "../pages/WRF/Action";

export const LoginRoute = ({
  component: Component,
  ...rest
}: RouteProps) => {
  if (!Component) {
    throw Error("component is undefined");
  }
  return (
    <Route
      {...rest}
      render={props => {         
        return isAuthenticated() ? (window.location.href.includes("dashboard/wrf/Action") ? 
        (         
          <Action {...props}/>
        ) 
        : 
        (         
          <Redirect to={{ pathname: "/dashboard/home", state: { from: props.location }}} />
        ))        
        :  
        (
          <Component {...props} />
        );
      }}
    />
  );
};
