import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { isAuthenticated } from "../helpers";

export const ProtectedRoute = ({
  component: Component,
  ...rest
}: RouteProps) => {
  if (!Component) {
    throw Error("component is undefined");
  }
  return (
    <Route
      {...rest}
      render={props => {
        return isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
};