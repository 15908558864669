import React, { useState, useContext, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { observer } from 'mobx-react';
import { Container } from "reactstrap";
import '../../assets/scss/style.scss';
import SecureLS from "secure-ls";
import IWRFAction from "../../entities/IWRFAction";
import WRFStore from "../../stores/wrfStore";
import * as custModal from '../Modal/Modal';
import GlobalUtils from '../../utils/Globals';
import { Key } from "../../utils";

var ls = new SecureLS();

const spanStyle = {
  marginLeft: '8%'
};

const spanResponseStyle = {
  fontSize: '14px'
};

const Action = (props : any) => {  
  const wrfStore = useContext(WRFStore);
  const {setWRFState, loadWRFByWRFNo} = wrfStore;  
  const [wrfRespMsg, setwrfRespMsg] = useState<any>(
    {
      WRFResponseMessage : ""
    }
  )
  
  const match = useRouteMatch();    
  var customModalBox = document.getElementById("customModalBox") as HTMLElement; 
  const customModalBoxClose = () => {
    customModalBox.style["display"] = "none";
    window.location.href = "/dashboard/WRF";   
  }
  
  useEffect(() => {   
    const ActionString = match.url.substring(match.url.lastIndexOf('/') + 1);     
      if(ls.get("cache4")!)
      {
        let WRFActionObject : IWRFAction = {'action': ActionString };        
        WRFActionObject.action = ActionString;

        if (ActionString.length > 0 && ActionString!= "login")
        {
          var customModalBox = document.getElementById("customModalBox") as HTMLElement;   
          const customModalBoxOpen = () => {
            customModalBox.style["display"] = "block";
          }
        
          const customModalBoxClose = () => {
            customModalBox.style["display"] = "none";
            window.location.href = "/dashboard/WRF";   
          }
          var key = Key.key;         
          let DecodedAction = GlobalUtils.ParameterToDecode(ActionString);
          let decryptedAction = GlobalUtils.decrypt(DecodedAction, key);
          const actionObject = JSON.parse(decryptedAction);
         
          if (actionObject.Action === "CRF" || actionObject.Action === "SRF")
          {      
            loadWRFByWRFNo(actionObject.WRFNo).then((response: any) => {           
              if (response.requestBy === ls.get("cache11")! && response.statusId === "CONVERSIONREQ")
              {
                window.location.href = `/dashboard/wrf/manage/${response.requestNo}/${response.entityId}/${response.applicationId}/${response.requestTypeId}/${response.year}`
              }
              else if (response.statusId != "CONVERSIONREQ")
              {
                setwrfRespMsg({WRFResponseMessage: "WRF is in " + response.statusId + " status. Conversion can not be performed."});
                customModalBoxOpen();
                setTimeout(function () {
                  customModalBoxClose();
                }, 10000, ); 
              }              
              else
              {
                setwrfRespMsg({WRFResponseMessage: "You are not allowed to perform this action."});
                customModalBoxOpen();
                setTimeout(function () {
                  customModalBoxClose();
                }, 10000, ); 
              }
            })            
          }
          else
          {
            setWRFState(WRFActionObject).then((response) => {
              setwrfRespMsg({WRFResponseMessage: response.data.message});
                            
              if (response.data.success) {                           
                customModalBoxOpen();
                setTimeout(function () {
                  customModalBoxClose();
                }, 10000, );                            
              }
            });  
          }                   
        }                 
        return () => console.log('unmounting...');
      }
      else
      {     
        window.location.href ="/Action/" + ActionString;
      }
  }, []); 
    
  return (
    <div className="app flex-row align-items-center bg-img">
      <Container>         
        <custModal.customModal headerIcon={"fa fa-info-circle"} close={customModalBoxClose} message={wrfRespMsg.WRFResponseMessage}/>
      </Container>
    </div>
  );
};

export default observer(Action);
