import jwt_decode from "jwt-decode";
import SecureLS from "secure-ls";
import { Logout } from "../services/Auth";

const isAuthenticated = (): boolean => {
  var ls = new SecureLS();
  const token: string | null = ls.get('cache1');
  if (!token) {
    logout();
    return false;
  }
  const decodedToken: any = jwt_decode(token);
  const tokenExpiration = decodedToken.exp;
  const dateNow = new Date();

  if (tokenExpiration < dateNow.getTime() / 1000) {
    return false;
  }
  return true;
};

const validateUsername = (username: string): boolean => {
  const USERNAME_REGEX = /([\w]+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const test: RegExp = new RegExp(USERNAME_REGEX);
  return test.test(username);
};

const logout = async() => {
  const response = await Logout();
};

export { isAuthenticated, validateUsername, logout };
