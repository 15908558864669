export enum Token_Constants {
  TOKEN_EXPIRED = "TOKEN_EXPIRED",
  TOKEN_EXPIRED_MESSAGE = "Session is expired. Please login again to continue",
}

export const RegexObject = {
  USERNAME_REGEX: /([\w]+)[.]([\w]+)/
};

export const AppConstants = {
  SERVER_URL: `https://CMSAPI.aku.edu`,
  APP_ID: 'CMS-WB'
};

export const SSRS_Path = {
    SSRS_URL: 'https://cmsreport.aku.edu/CMSReportTemplate.aspx/'
}

export const SessionID_ChangePassword = {
  SessionID_ChangePassword: '1',
}

export const Password_Hint= {
  Password_Hint: 'Password must contain one letter, one special character and a number.',
}

export const WRF_File_Attachment_Download_Path= {
  DownloadFilePath: "/CMSTempFiles/"
}

export const Board_Role_Permissions= {
 TeamBoardRolePermissions : ["TEAMLEAD","SUPPORTPERSON","ADMIN"]
}

export const Tasks_Permissions= {
  TasksPermissions : ["ADMIN","TEAMLEAD","SUPPORTPERSON","USER","MANAGER"]
}

export const Dashboard_Permissions= {
  DashboardPermissions : ["ADMIN","TEAMLEAD","SUPPORTPERSON"]
}

export const Voice_Search = {
  IsEnabled: true,
}

export const Menu_String = {
  menuString : '[{"Controller":"Login"},{"Controller": "Logout"},{"Controller": "ForgotPassword"}' +
  ',{"Controller": "ChangePassword"},{"Controller":"Home"},{"Controller":"layout"}]'
}

export const Key = {
  // key : Application Key CMS-WB from Application. Add XXX + 7 Key chars + XX + remaining Key chars + XX. 
  // Encode Key
  // Add XX + Key + XX
  key : "00Q01TMDBkJDA1TldCQDE4dCoyMDAwMjA=00"
}