import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ProtectedRoute } from "./utils";
import {LoginRoute} from './utils/LoginRoute';
import SecureLS from "secure-ls";
import { Menu_String } from "./utils/constants";

var ls = new SecureLS();

const loading = () => (  
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

const Login = React.lazy(() => import("./pages/Login/Login"));
const ForgotPassword = React.lazy(() => import("./pages/Login/ForgotPassword"));
const DefaultLayout = React.lazy(() => import("./components/layout"));

export const history = createBrowserHistory();

let menuString = ls.get("cache5");

function App() {
  let menuItems: any;
  let authenticated: boolean = false;

  if (!menuString)
  {
    menuString = Menu_String.menuString;
  }

  menuItems = JSON.parse(menuString);
  
  for (let i = 0; i < menuItems.length; i++) {
    if ((history.location.pathname.includes(menuItems[i].Controller)) && !menuItems[i].HasSubMenus) {
      authenticated = true;
      break;
    }
  }

  return (
    authenticated 
    ?(
      window.location.href.includes("dashboard/wrf/Action")
      ?(  
        <Router>
          <React.Suspense fallback={loading()}>
            <Switch>
              <LoginRoute path="/dashboard/wrf/Action/:sAction" component={Login} />
            </Switch>
          </React.Suspense>
        </Router>
      )
      :( 
        <Router>
          <React.Suspense fallback={loading()}>
            <Switch>
              <LoginRoute path="/login" component={Login} />         
              <ForgotPassword path="/Pages" component={ForgotPassword} />
              <ProtectedRoute path="/dashboard" component={DefaultLayout} />        
              <Redirect from="/*" to="/dashboard/home" />
            </Switch>
          </React.Suspense>
        </Router>
      )
    )
    :(
      <Router>
        <React.Suspense fallback={loading()}>
          <Switch>
            <LoginRoute path="/login" component={Login} />
            <ForgotPassword path="/Pages" component={ForgotPassword} />
            <ProtectedRoute path="/dashboard/" component={DefaultLayout} />
            <Redirect from="/*" to="/dashboard/home"  />
          </Switch>
        </React.Suspense>
      </Router>
    )
  );
}

export default App;