import {request} from './api'
import ILinkedWRF from '../entities/ILinkedWRF';

const setService = {
    list: (userSessionParam: string) : Promise<any> => request.get(`/LinkedWRF/GetAll?a_sObjType=LinkedWRF&${userSessionParam}`),
    detail: (id: number) => request.get(`/LinkedWRF/GetLinkedWRFDetailsByID?a_lSupportWRFNo=${id}`),
    create:(linkedWrf: ILinkedWRF, userSessionParam: string) => request.post(`/LinkedWRF/Create?${userSessionParam}`,linkedWrf),
    update: (linkedWrf: ILinkedWRF, userSessionParam: string) => request.post(`/LinkedWRF/Update?${userSessionParam}`,linkedWrf),
}

export default setService;