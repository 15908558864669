import {request} from './api'
import IWRFFeedBack from '../entities/IWRFFeedBack';

const wrfFeedBackService = {
    list: (userSessionParam: string) : Promise<any> => request.get(`/WRFFeedBack/GetAll?a_sObjType=WRFFeedBack&${userSessionParam}`),
    detail: (iWRFFeedBackID: number) => request.get(`/WRFFeedBack/GetWRFFeedBackDetailsByID?a_iWRFNo=${iWRFFeedBackID}`),
    create: (wrfFeedBack: IWRFFeedBack, userSessionParam: string) => request.post(`/WRFFeedBack/Create?${userSessionParam}`,wrfFeedBack),
    update: (wrfFeedBack: IWRFFeedBack, userSessionParam: string) => request.post(`/WRFFeedBack/Update?${userSessionParam}`,wrfFeedBack)
}

export default wrfFeedBackService;
