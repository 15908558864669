import { observable, computed, action, runInAction, toJS } from "mobx";
import { createContext } from "react";
import IWrf from './../entities/IWrf';
import wrfService from './../services/wrfService';
import GlobalUtils from '../utils/Globals';
import SimpleReactValidator from "simple-react-validator";
import qs from "query-string";
import SecureLS from "secure-ls";
import moment from "moment";
import IWRFAction from "../entities/IWRFAction";
import IWRFFeedBack from "../entities/IWRFFeedBack";
import wrfFeedBackService from "../services/wrfFeedBackService";
import ILinkedWRF from "../entities/ILinkedWRF";
import linkedWrfService from "../services/linkedWrfService";

var ls = new SecureLS();

const params = {
    a_sUserSetID: ls.get('cache7'),
    a_sUserEntityID: ls.get('cache8'),
    a_sUserSessionID: ls.get("cache4"),
    a_sUserID: ls.get('cache11'),
    a_sWorkstation: ls.get('cache9'),
  };
let userSessionParam = qs.stringify(params); 

var wrfsData: { data: any[]; };
var wrfTeamBoard: { data: any[]; };
var wrfTeamBoardActivities: { data: any[]; };

class WRFStore {
    @observable wrfRegistry = new Map();
    @observable wrfTeamRegistry = new Map();
    @observable wrfTeamActivitiesRegistry = new Map();
    @observable wrfInstancelogsRegistry = new Map();
    @observable wrfEmailRegistry: string[] = [];
    @observable wrf: IWrf | null = null;
    @observable linkedWrf: ILinkedWRF | null = null;
    @observable submitting: boolean = false;
    @observable isWrfApproved: boolean = false;
    @observable comment: string = "";
    @observable wrfStateDate: string = "";
    @observable lastState: string = new Date().getFullYear() + '-' + ( "0" + (new Date().getMonth() + 1)).slice(-2) + '-' + ("0" + new Date().getDate()).slice(-2);
    @observable processId: number = 0;
    @observable approvedBy: string = "";
    @observable target: string = "";
    @observable loadingInitial: boolean = false;
    @observable hideApprovalReqStatusBtn: boolean = true;
    @observable hideResponseReqStatusBtn: boolean = true;
    @observable hideApproveStatusBtn: boolean = true;
    @observable hideRejectStatusBtn: boolean = true;
    @observable hideInProgressStatusBtn: boolean = true;
    @observable hideBOApprovalReqStatusBtn: boolean = true;
    @observable hideBOApproveStatusBtn: boolean = true;
    @observable hideBORejectStatusBtn: boolean = true;
    @observable hideDevCompleteStatusBtn: boolean = true;
    @observable hideQAStatusBtn: boolean = true;
    @observable hideUATSignoffReqStatusBtn: boolean = true;
    @observable hideUATInProgressStatusBtn: boolean = true;
    @observable hideUATSignoffStatusBtn: boolean = true;
    @observable hideImplementedStatusBtn: boolean = true;
    @observable hideClosedStatusBtn: boolean = true;
    @observable hideFinanceApprovalReqStatusBtn: boolean = true;
    @observable hideFinanceApproveStatusBtn: boolean = true;
    @observable hideFinanceRejectStatusBtn: boolean = true;
    @observable hideCancelWrfStatusBtn: boolean = true;
    @observable hideCancelStatusBtn: boolean = true;
    @observable hideSignoffStatusBtn: boolean = true;
    @observable hideReopenStatusBtn: boolean = true;
    @observable hideUpdateBtn: boolean = true;
    @observable hideCompleteStatusBtn: boolean = true;
    @observable hideConversionReqToCRFStatusBtn: boolean = true;
    @observable hideConversionReqToSRFStatusBtn: boolean = true;
    @observable hideConvertToCRFStatusBtn: boolean = true;
    @observable hideConvertToSRFStatusBtn: boolean = true;     
    @observable wrfFeedBackRegistry = new Map();
    @observable wrfFeedBack: IWRFFeedBack | null = null;
    
    @computed get wrfByDate() {
        return Array.from(this.wrfRegistry.values()).sort(
            (a, b) => Date.parse(b.lastFileDtTm) - Date.parse(a.lastFileDtTm)
        );
    }

    @computed get wrfByTeam() {
        return Array.from(this.wrfTeamRegistry.values()).sort(
            (a) => a.taskType
        );
    }

    @computed get wrfActivityByTeam() {
        return Array.from(this.wrfTeamActivitiesRegistry.values()).sort(
            (a) => a.taskType
        );
    }

    @computed get myTeamMemberIDs() {
        let myTeamsTasks: any[]= [];
        
        Array.from(this.wrfTeamRegistry.values()).map(name => {
            if (myTeamsTasks.indexOf(name.supportPersonID) === -1) {
                myTeamsTasks.push(name.supportPersonID)
            }
        });
        Array.from(this.wrfTeamActivitiesRegistry.values()).map(name => {
            if (myTeamsTasks.indexOf(name.activityAssignedTo) === -1) {
                myTeamsTasks.push(name.activityAssignedTo)
            }
        });
        return myTeamsTasks;
    }

    @computed get lastStateDate() {
        return this.lastState;
    }

    @action isWRFLoaded = () => {       
        if (wrfsData) {
            return true;
        }
        else {
            return false;
        }
    }

    /* Action Methods */
    @action loadWrfs = async () => {
        this.loadingInitial = true;
        this.wrfRegistry.clear();
        try {
            const wrfs = await wrfService.list(userSessionParam);
            runInAction("fetching WRFs", () => {
                wrfs.data.forEach((wrf: any) => {
                    const key =  [wrf.requestNo, wrf.entityId, wrf.applicationId, wrf.requestTypeId, wrf.year]
                    this.wrfRegistry.set((JSON.stringify(key)), wrf);
                });
            });
        } 
        catch (error) {
            console.log(error);
        } 
        finally {
            runInAction("finishing loading WRFs", () => {
                this.loadingInitial = false;
            });
        }
    };

    @action loadWRFByWRFNo= async (wrfNo: number) => { 
        this.loadingInitial = true;
        try {           
          const wrfDetails = await wrfService.detailByWrfNo(wrfNo);           
          return wrfDetails.data;
        } 
        catch (error) {
          console.log(error);
        } 
        finally {
          runInAction("finishing loading wrf detail", () => {
            this.loadingInitial = false;
        });
      }
     };

    @action loadWrfsByKey = async (searchKey: string, sessionParams: string) => {
        this.loadingInitial = true;
        
        try {          
            userSessionParam = sessionParams;
            wrfsData = await wrfService.listBySearchKey(searchKey, sessionParams);
            runInAction("fetching WRFs", () => {
                this.wrfRegistry = new Map();
                wrfsData.data.forEach((wrf: any) => {
                    const key =  [wrf.requestNo, wrf.entityId, wrf.applicationId, wrf.requestTypeId, wrf.year]
                    this.wrfRegistry.set((JSON.stringify(key)), wrf);
                });
            });
        } 
        catch (error) {
            console.log(error);
        } 
        finally {
            runInAction("finishing loading WRFs", () => {
                this.loadingInitial = false;
            });
        }
    };

    @action loadSingleWrf = async (requestNo: number, entityId: string, applicationId: string, requestTypeId: string, year: number) => {
        try {
            let WrfData = this.getWrf(requestNo, entityId, applicationId, requestTypeId, year);
            if (WrfData) {
                this.wrf = WrfData;
                return toJS(WrfData);
            }
            else {
                // call from api   
                let response: any = await wrfService.details(requestNo, entityId, applicationId, requestTypeId, year);
                WrfData = response.data.mdlWRF;
                runInAction('loading WRF', () => {
                    this.wrf = WrfData;
                })
                return WrfData;
            }
        }
        catch (error) {
            console.log(error);
        }
        finally {
        }
    };

    @action loadWRFStats = async () => { 
        this.loadingInitial = true;
        try {
          const wrfStats = await wrfService.listWRFStats(userSessionParam);
          return wrfStats.data;
        } 
        catch (error) {
          console.log(error);
        } 
        finally {
          runInAction("finishing loading wrf Stats", () => {
            this.loadingInitial = false;
        });
      }
    };

    @action isTeamBoardLoaded = () => {
        if (wrfTeamBoard) {
            return true;
        }
        else {
            return false;
        }
    }

    @action loadTeamBoard = async (userid: string) => {
        this.loadingInitial = true;
        this.wrfTeamRegistry.clear();
        this.wrfTeamActivitiesRegistry.clear();
        try {
            var loop = 0;
            this.isTeamBoardLoaded();
            wrfTeamBoard = await wrfService.listTeamBoard(userid);
            wrfTeamBoardActivities = await wrfService.listTeamBoardActivities(userid);
            runInAction("fetching WRFs", () => {
                wrfTeamBoard.data.forEach((wrf: any) => {
                    const key = [loop, wrf.requestNo, wrf.entityID, wrf.applicationId, wrf.requestTypeID, wrf.year]
                    this.wrfTeamRegistry.set((JSON.stringify(key)), wrf);
                    loop = loop + 1;
                });
                wrfTeamBoardActivities.data.forEach((wrf: any) => {
                    const key = [loop, wrf.requestNo, wrf.entityID, wrf.applicationId, wrf.requestTypeID, wrf.year]
                    this.wrfTeamActivitiesRegistry.set((JSON.stringify(key)), wrf);
                    loop = loop + 1;
                });
            });
        }
        catch (error) {
            console.log(error);
        }
        finally {
            runInAction("finishing loading Team Board", () => {
                this.loadingInitial = false;
            });
        }
    };

    @action loadStatus = async (wrfNo: number, processId: number, status: string) => {
        try {
            let response: any = await wrfService.wrfStatusAchieved(wrfNo,processId,status);
                response = response.data;
                runInAction('loading WRF', () => {
                    this.isWrfApproved = response;
                })
            }
            catch (error) {
                console.log(error);
            }
            finally {
            }
            return this.isWrfApproved;
        };

    @action loadStatusDate = async (wrfNo: number, processId: number, status: string) => {
        try {
            let response: any = await wrfService.wrfStatusDate(wrfNo,processId,status);
                response = response.data;
                runInAction('loading WRF', () => {
                    this.wrfStateDate = response;
                })
            }
            catch (error) {
                console.log(error);
            }
            finally {
            }
            return this.wrfStateDate;
        };

    @action loadProcessId = async (requestTypeId: string) => {
        try {
            let response: any = await wrfService.getProcessId(requestTypeId);
                response = response.data;
                runInAction('loading WRF', () => {
                    this.processId = response;
                })
            }
            catch (error) {
                console.log(error);
            }
            finally {
            }
            return this.processId;
        };

    @action loadUserByStatus = async (processStatusId: string, wrfNo: number, processId: number) => {
        try {
            let response: any = await wrfService.processInstanceLogByStatus(processStatusId,wrfNo,processId);
                runInAction('loading WRF', () => {
                    this.approvedBy = response.data.lastFileUser;
                })
            }
            catch (error) {
                console.log(error);
            }
            finally {
            }
            return this.approvedBy;
        };

    @action loadWRFInstanceLogs = async (wrfNo: number, processId: number) => {
        try {
            let response: any = await wrfService.listProcessInstanceLog(wrfNo,processId);
                runInAction("fetching WRF Instance Logs", () => {
                    this.wrfInstancelogsRegistry = new Map();
                    response.data.forEach((instanceLog: any) => {
                        const key =  [instanceLog.processLogNo]
                        this.wrfInstancelogsRegistry.set((JSON.stringify(key)), instanceLog);
                        this.lastState = instanceLog.startDate;
                    });
                });
                return Array.from(this.wrfInstancelogsRegistry.values()).sort(
                    (a, b) => Date.parse(b.startDate) - Date.parse(a.startDate)
                );
            }
            catch (error) {
                console.log(error);
            }
            finally {
            }
            return this.approvedBy;
        };

    @action loadWRFEmailLogs = async (entityId: string, wrfId: string) => {
        try {
            let email: any = await wrfService.listEmailLog(entityId, wrfId);     
            runInAction("fetching WRF Instance Logs", () => {
                this.wrfEmailRegistry = [];
                email.data.forEach((emailLog: any) => {
                    this.wrfEmailRegistry.push(emailLog);
                })
            });
  
        }
        catch (error) {
            console.log(error);
        }
        finally {
        }

        return this.wrfEmailRegistry;
    };
            
    @action loadCommentByStatus = async (processStatusID: string, wrfNo: number, processID: number) => {
        try {
            let response: any = await wrfService.processInstanceLogByStatus(processStatusID,wrfNo,processID);
                runInAction('loading WRF', () => {
                    this.comment = response.data.comments;
                })
            }
            catch (error) {
                console.log(error);
            }
            finally {
            }
            return this.comment;
        };

        @action loadLinkedWrf = async (linkedWrfNo: number) => {
            try {                                 
                let response : any = await linkedWrfService.detail(linkedWrfNo);     
                let linkedWrfData = response.data.mdlLinkedWRF; 
                return linkedWrfData;            
            } 
            catch (error) {
                console.log(error);
            } 
            finally { 
                this.submitting = false;   
            }    
        };

    @action editWrf = async (wrf: IWrf, sessionParams: string) => {       
        this.submitting = true;
        try {
            // call api for updating entity security
            userSessionParam = sessionParams;
            let response = await wrfService.update(wrf, sessionParams);       
            runInAction("updating WRFs", () => {
                const key =  [wrf.requestNo, wrf.entityId, wrf.applicationId, wrf.requestTypeId, wrf.year]
                this.wrfRegistry.set(JSON.stringify(key), wrf);
            });
            return response;
        }
        catch (error) {
            console.log(error);
        }
        finally {
            runInAction("finishing updating WRFs", () => {
                this.submitting = false;
            });
        }
    };

    @action editLinkedWrf = async (linkedWrf: ILinkedWRF, sessionParams: string) => {       
        this.submitting = true;
        try {
            // call api for updating entity security
            userSessionParam = sessionParams;
            let response = await linkedWrfService.update(linkedWrf, sessionParams);       
            runInAction("updating LinkedWRFs", () => {
                const key =  [linkedWrf.suppwrfno]
                this.wrfRegistry.set(JSON.stringify(key), linkedWrf);
            });
            return response;
        }
        catch (error) {
            console.log(error);
        }
        finally {
            runInAction("finishing updating WRFs", () => {
                this.submitting = false;
            });
        }
    };

    @action createWrf = async (wrf: IWrf, sessionParams: string) => {        
        this.submitting = true;
        try {           
            // call api for updating entity security
            userSessionParam = sessionParams;
            let response = await wrfService.create(wrf, sessionParams);           
            runInAction("creating WRF", () => {
                const key =  [wrf.requestNo, wrf.entityId, wrf.applicationId, wrf.requestTypeId, wrf.year]
                this.wrfRegistry.set(JSON.stringify(key), wrf);
            });
            return response;
        }
        catch (error) {
            console.log(error);
        }
        finally {
            runInAction("finishing updating WRF", () => {
                this.submitting = false;
            });
        }
    };

    @action createLinkedWrf = async (wrf: IWrf, sessionParams: string) => {        
        this.submitting = true;
        try {           
            // call api for updating entity security
            userSessionParam = sessionParams;
            let response = await wrfService.createLinkedWrf(wrf, sessionParams);           
            runInAction("creating WRF", () => {
                const key =  [wrf.requestNo, wrf.entityId, wrf.applicationId, wrf.requestTypeId, wrf.year]
                this.wrfRegistry.set(JSON.stringify(key), wrf);
            });
            return response;
        }
        catch (error) {
            console.log(error);
        }
        finally {
            runInAction("finishing updating WRF", () => {
                this.submitting = false;
            });
        }
    };
    
    @action clearWrf = () => {
        this.wrf = null;
    };
    /* End Action Methods */

    /* Helper Methods */
    getWrf = (requestNo: number, entityId: string, applicationId: string, requestTypeId: string, year: number) => {
        const key =  [requestNo, entityId, applicationId, requestTypeId, year];
        const wrf: IWrf = this.wrfRegistry.get(JSON.stringify(key));
        return wrf;
    };

    @action handleSubmit = (comments: string, stateDate: string, status: string, wrfS: IWrf,
        sRequestNo: string, sEntityId: string, sApplicationId: string, sRequestTypeId: string, sWrfNo: number,
        validator: React.MutableRefObject<SimpleReactValidator>, bValid: boolean, refreshStatusBtns: any, loadingOpen: any, loadingClose: any, ParameterValue : string, closeInputModal: () => void, sessionParams: string, ratingId?: number, feedBackComment?: string, conversionType?: string) => {                    
            const WRFFeedBack = ({   
                wrfno: 0,
                ratingId: 0,   
                comments: "",    
                workstation: ls.get("cache9")!,
                lastFileDtTm: GlobalUtils.getCurrentDateToLocaleString(),
                lastFileUser: ls.get("cache11")!,
              });
                           
            if(status === "SIGNOFF")
            {
                const newWRFFeedBack: IWRFFeedBack = { ...WRFFeedBack, wrfno: wrfS.wrfNo, ratingId: ratingId!, comments: feedBackComment!,
                workstation: ls.get("cache9")!,
                lastFileDtTm: GlobalUtils.getCurrentDateToLocaleString(),
                lastFileUser: ls.get("cache11")!, };
                
                this.loadWRFFeedBack(wrfS.wrfNo).then((FeedBack) => {
                  if (FeedBack?.wrfno == 0) 
                  {       
                    this.createWRFFeedBack(newWRFFeedBack)             
                  } 
                  else 
                  {
                    this.editWRFFeedBack(newWRFFeedBack);             
                  }        
                });
            }            

            let region = Intl.DateTimeFormat().resolvedOptions().timeZone;            
            loadingOpen();     
            const newWRF: IWrf = {
            ...wrfS,
            lastFileDtTm: moment().format("MM/DD/YYYY HH:mm"),
            lastFileUser: ls.get("cache11")!,
            workstation: ls.get("cache9")!,
            ccbstatusId: status.includes("CCB") ? status.split('-')[1] : wrfS.ccbstatusId,
            statusId: status.includes("CCB") ? "" : status,
            requestNo: + wrfS.requestNo,
            projectId: + wrfS.projectId,
            priorityId: + wrfS.priorityId,
            desc: wrfS.desc,
            significanceId: + wrfS.significanceId,
            savingHours: + wrfS.savingHours,
            estimatedHours: + wrfS.estimatedHours,
            actualHours: + wrfS.actualHours,
            comment: comments,
            requestDate: GlobalUtils.setParseDateToLocalRegion( wrfS.requestDate),
            stateDate: GlobalUtils.setParseDateToLocalRegion(stateDate),
            userExpectedDate: GlobalUtils.setParseDateToLocalRegion(wrfS.userExpectedDate),
            isdExpectedDate: GlobalUtils.setParseDateToLocalRegion(wrfS.isdExpectedDate),
            isdResponseDate: GlobalUtils.setParseDateToLocalRegion(wrfS.isdResponseDate),
            rank: Number(wrfS.rank),
            actionTaken: wrfS.requestTypeId === "SUPP" ? (conversionType + (wrfS.actionTaken === "undefined" ? "" : wrfS.actionTaken)) : wrfS.actionTaken,
        };
        if (bValid || ParameterValue.split(",").includes(status)) {
            if (validator.current.allValid() || ParameterValue.split(",").includes(status)) {                             
                if (!sRequestNo && !sEntityId && !sApplicationId && !sRequestTypeId) {                                     
                    this.createWrf(newWRF, sessionParams).then((response: any) => {
                        if (response) {
                            closeInputModal();
                            refreshStatusBtns();
                            loadingClose();
                        }
                    });
                }
                else if (sWrfNo && sRequestTypeId) {                   
                    newWRF.departmentId = '(' + wrfS.setId + ')' + wrfS.departmentId ;         
                    this.createLinkedWrf(newWRF, sessionParams).then((response: any) => {
                        if (response.data.success) {
                            this.loadLinkedWrf(sWrfNo).then((responseData: any) => {                              
                                if(responseData) {
                                    const linkedWRF: ILinkedWRF = {
                                        suppwrfno: sWrfNo,
                                        linkedWrfno: response.data.mdlWRF.wrfNo,
                                        conversionRequestedBy: responseData.conversionRequestedBy,
                                        lastFileDtTm: moment().format("MM/DD/YYYY HH:mm"),
                                        lastFileUser: ls.get("cache11")!,
                                        workstation: ls.get("cache9")!
                                    }                                   
                                    this.editLinkedWrf(linkedWRF, sessionParams).then((updatedLinked: any) => {
                                        if(updatedLinked)
                                        {
                                            this.loadWRFByWRFNo(sWrfNo).then((WRFData : any) => {
                                                WRFData.statusId = "CONVERTED";                                           
                                                this.editWrf(WRFData, sessionParams).then((response: any) => {
                                                    if (response) {
                                                        closeInputModal();
                                                        refreshStatusBtns();
                                                        loadingClose();
                                                    }
                                                });
                                                closeInputModal();
                                                refreshStatusBtns();
                                                loadingClose();
                                            })                                                                                         
                                        }
                                    });
                                }
                            })
                            closeInputModal();
                            refreshStatusBtns();
                            loadingClose();
                        }
                    });
                }
                else {                               
                    this.editWrf(newWRF, sessionParams).then((response: any) => {
                        if (response) {
                            closeInputModal();
                            refreshStatusBtns();
                            loadingClose();
                        }
                    });
                }
            }
            else {
                validator.current.showMessages();
            }
        }
    };

    @action setWRFState = async (action: IWRFAction) => {
        const SessionParam = {
          a_sUserSetID: ls.get('cache7'),
          a_sUserEntityID: ls.get('cache8'),
          a_sUserSessionID: ls.get("cache4"),
          a_sUserID: ls.get('cache11'),
          a_sWorkstation: ls.get('cache9'),
        }; 
              
        const SessionParameters = qs.stringify(SessionParam);          
        const response : any = await wrfService.updateWRFState(action, SessionParameters); 
         
       return response;

      };

    @action handleBulkSubmit = (comments: string, stateDate: string, status: string, wrfS: IWrf, UserSessionParameters: string)  => {
        this.submitting = true;                          
            const newWRF: IWrf = {
            ...wrfS,
            lastFileDtTm: GlobalUtils.getCurrentDateToLocaleString(),
            lastFileUser: ls.get("cache11")!,
            workstation: ls.get("cache9")!,
            ccbstatusId: status.includes("CCB") ? status.split('-')[1] : wrfS.ccbstatusId,
            statusId: status.includes("CCB") ? "" : status,
            requestNo: + wrfS.requestNo,
            projectId: + wrfS.projectId,
            priorityId: + wrfS.priorityId,
            desc: wrfS.desc,
            significanceId: + wrfS.significanceId,
            savingHours: + wrfS.savingHours,
            estimatedHours: + wrfS.estimatedHours,
            actualHours: + wrfS.actualHours,
            comment: comments,
            stateDate: stateDate,
            rank: Number(wrfS.rank)
        };
        let result = this.editWrf(newWRF, UserSessionParameters).then((response: any) => {               
            let result = response;
            this.submitting = false;
            return result; 
        });
        
        return result;                    
    }           

    @action setApprovalReqStatusBtnsVisibility = (flag: boolean) => {
        this.hideApprovalReqStatusBtn = flag;
    };
    @action setResponseReqStatusBtnsVisibility = (flag: boolean) => {
        this.hideResponseReqStatusBtn = flag;
    };
    @action setApproveStatusBtnsVisibility = (flag: boolean) => {
        this.hideApproveStatusBtn = flag;
    };
    @action setRejectStatusBtnsVisibility = (flag: boolean) => {
        this.hideRejectStatusBtn = flag;
    };
    @action setInProgressStatusBtnsVisibility = (flag: boolean) => {
        this.hideInProgressStatusBtn = flag;
    };
    @action setBOApproveStatusBtnsVisibility = (flag: boolean) => {
        this.hideBOApproveStatusBtn = flag;
    };
    @action setBOApprovalReqStatusBtnsVisibility = (flag: boolean) => {
        this.hideBOApprovalReqStatusBtn = flag;
    };
    @action setBORejectStatusBtnsVisibility = (flag: boolean) => {
        this.hideBORejectStatusBtn = flag;
    };
    @action setDevCompleteStatusBtnsVisibility = (flag: boolean) => {
        this.hideDevCompleteStatusBtn = flag;
    };
    @action setUATSignoffReqStatusBtnsVisibility = (flag: boolean) => {
        this.hideUATSignoffReqStatusBtn = flag;
    };
    @action setQAStatusBtnsVisibility = (flag: boolean) => {
        this.hideQAStatusBtn = flag;
    };
    @action setUATInProgressStatusBtnsVisibility = (flag: boolean) => {
        this.hideUATInProgressStatusBtn = flag;
    };
    @action setUATSignoffStatusBtnsVisibility = (flag: boolean) => {
        this.hideUATSignoffStatusBtn = flag;
    };
    @action setImplementedStatusBtnsVisibility = (flag: boolean) => {
        this.hideImplementedStatusBtn = flag;
    };
    @action setClosedStatusBtnsVisibility = (flag: boolean) => {
        this.hideClosedStatusBtn = flag;
    };
    @action setFinanceApproveStatusBtnsVisibility = (flag: boolean) => {
        this.hideFinanceApproveStatusBtn = flag;
    };
    @action setFinanceApprovalReqStatusBtnsVisibility = (flag: boolean) => {
        this.hideFinanceApprovalReqStatusBtn = flag;
    };
    @action setFinanceRejectStatusBtnsVisibility = (flag: boolean) => {
        this.hideFinanceRejectStatusBtn = flag;
    };
    @action setCancelWrfStatusBtnsVisibility = (flag: boolean) => {
        this.hideCancelWrfStatusBtn = flag;
    };
    @action setCancelStatusBtnsVisibility = (flag: boolean) => {
        this.hideCancelStatusBtn = flag;
    };
    @action setUpdateBtnVisibility = (flag: boolean) => {
        this.hideUpdateBtn = flag;
    };
    @action setSignoffStatusBtnsVisibility = (flag: boolean) => {
        this.hideSignoffStatusBtn = flag;
    };
    @action setReopenBtnVisibility = (flag: boolean) => {
        this.hideReopenStatusBtn = flag;
    };
    @action setCompleteStatusBtnVisibility = (flag: boolean) => {
        this.hideCompleteStatusBtn = flag;
    };
    @action setConversionReqToCRFStatusBtnVisibility = (flag: boolean) => {
        this.hideConversionReqToCRFStatusBtn = flag;
    };
    @action setConversionReqToSRFStatusBtnVisibility = (flag: boolean) => {
        this.hideConversionReqToSRFStatusBtn = flag;
    };
    @action setConvertToCRFStatusBtnVisibility = (flag: boolean) => {
        this.hideConvertToCRFStatusBtn = flag; 
    };
    @action setConvertToSRFStatusBtnVisibility = (flag: boolean) => {
        this.hideConvertToSRFStatusBtn = flag; 
    };
    @computed get wrfFeedBackByID() {
        return Array.from(this.wrfFeedBackRegistry.values()).sort(
          (b)=> +b.wrfFeedBackID - 0
        );
      }
    
      /* Action Methods */
      @action loadWRFFeedBacks = async () => {    
        this.loadingInitial = true;
        try {
          const wrfFeedBacks = await wrfFeedBackService.list(userSessionParam);
          runInAction("fetching WRFFeedBacks", () => {
            wrfFeedBacks.data.forEach((wrfFeedBack: any) => {
              this.wrfFeedBackRegistry.set(wrfFeedBack.wrfFeedBackId, wrfFeedBack);
            });
          });
        } 
        catch (error) {
          console.log(error);
        } 
        finally {
          runInAction("finishing loading WRFFeedBacks", () => {
            this.loadingInitial = false;
          });
        }
      };
      
      @action loadWRFFeedBack = async (wrfNo: number) => {
       try {    
         let wrfFeedBackData = this.getWRFFeedBack(wrfNo);
          if (wrfFeedBackData) {
            this.wrfFeedBack = wrfFeedBackData;
            return toJS(wrfFeedBackData);
          } 
          else {
            // call from api     
            let response : any = await wrfFeedBackService.detail(wrfNo);     
            wrfFeedBackData = response.data.mdlWRFFeedBack;            
            return wrfFeedBackData;         
          }
        } 
        catch (error) {
          console.log(error);
        } 
        finally {    
        }    
      };
    
      @action editWRFFeedBack = async (wrfFeedBack: IWRFFeedBack) => {
        this.submitting = true;
        try {
          // call api for updating WRFFeedBack
          await wrfFeedBackService.update(wrfFeedBack, userSessionParam);
          runInAction("updating WRFFeedBack", () => {
            this.wrfFeedBackRegistry.set(wrfFeedBack.ratingId, wrfFeedBack);
          });
        } 
        catch (error) {
          console.log(error);
        } 
        finally {
          runInAction("finishing updating WRFFeedBack", () => {
            this.submitting = false;
          });
        }
      };
    
      @action createWRFFeedBack = async (wrfFeedBack: IWRFFeedBack) => {
        this.submitting = true;
        try {         
          // call api for creating WRFFeedBack
          await wrfFeedBackService.create(wrfFeedBack, userSessionParam);
          runInAction("creating WRFFeedBack", () => {
            this.wrfFeedBackRegistry.set(wrfFeedBack.ratingId, wrfFeedBack);
          });
        } 
        catch (error) {
          console.log(error);
        } 
        finally {
          runInAction("finishing creating WRFFeedBack", () => {
            this.submitting = false;
          });
        }
      };

      getWRFFeedBack = (wrfFeedBackID: number) => {
        const key = [wrfFeedBackID];
        const wrfFeedBack: IWRFFeedBack = this.wrfFeedBackRegistry.get(JSON.stringify(key));
        return wrfFeedBack;
      };
}

export default createContext(new WRFStore());
function WRFFeedBackStore(WRFFeedBackStore: any): { loadWRFFeedBack: any; createWRFFeedBack: any; editWRFFeedBack: any; wrfFeedBack: any; wrfFeedBackByID: any; } {
    throw new Error("Function not implemented.");
}

