import axios, { AxiosResponse, AxiosError } from "axios";
import SecureLS from "secure-ls";
import qs from "query-string";
import IAuth from "./../entities/IAuth";
import { AppConstants, Key } from "../utils/constants";
import jwt_decode from "jwt-decode";
import GlobalsUtils from "../utils/Globals";

axios.defaults.baseURL = AppConstants.SERVER_URL;

interface IResponseBody {
  success: boolean;
  data?: {};
  status?: string;
  message?: string;
}

var ls = new SecureLS();

const Authenticate = (auth: IAuth): Promise<IResponseBody> => {
  const params = {
    UserName: auth.UserName,
    Password: auth.Password,
    Domain: auth.Domain,      
  };

  const encryptedParams = GlobalsUtils.encrypt(JSON.stringify(params), Key.key);
 
  return axios
    .post(`/api/auth/authenticate?a_sqsData=`+ GlobalsUtils.ParameterToEncode(encryptedParams), null, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then((response: AxiosResponse) => {
      const { data } = response;      
      
      if (data.success === true) {
        var key = Key.key;
        var decryptedData = GlobalsUtils.decrypt(data.token, key);
        const decodedToken: any = jwt_decode(decryptedData);
        const detailsObject = JSON.parse(decodedToken.Details);
        
        ls.set("cache1", decryptedData);
        ls.set("cache2", detailsObject.Name);
        ls.set("cache3", detailsObject.Roles);
        ls.set("cache4", detailsObject.SessionId);
        ls.set("cache5", JSON.stringify(detailsObject.Menus));
        ls.set("cache6", JSON.stringify(detailsObject.Entities));
        ls.set("cache7", detailsObject.DefaultSetId);
        ls.set("cache8", detailsObject.DefaultEntityId);
        ls.set("cache9", detailsObject.WorkStation);
        ls.set("cache10", detailsObject.DepartmentID);
        ls.set("cache11", detailsObject.UserId);
      
        return {
          success: true,
          status: "200"
        };
      }
      return {
        success: false,
        message: data.message,
        status: data.status
      };
    })
    .catch((error: AxiosError) => {
      return {
        success: false,
        status: error.response?.status.toString(),
        message: error.message,        
      };
    });
};
  
const Logout = (): Promise<IResponseBody> => {
  const params = {
    a_sSessionId: ls.get("cache4"),
  };
  const queryParams = qs.stringify(params);
  return axios
    .post(`/api/auth/logout`, queryParams, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
    .then((response: AxiosResponse) => {
      const { data } = response;

      if (data.success === true) {
        return {
          success: true,
          status: "200"
        };
      }
      return {
        success: false,
        message: data.message
      };
    })
    .catch((error: AxiosError) => {
      return {
        success: false,
        status: error.response?.status.toString(),
        message: error.message
      };
    });
};

export {Authenticate, Logout};
