import { activityRequest, request } from "./api";
import IWrf from "./../entities/IWrf";
import IWRFAction from "../entities/IWRFAction";

const wrfService = {
  list: (userSessionParam: string): Promise<any> => request.get(`/wrf/GetAll?a_sObjType=WRF&${userSessionParam}`),
  listBySearchKey: (searchKey: string, userSessionParam: string): Promise<any> => request.get(`/wrf/GetAllBySearchKey?a_sSearchKey=${searchKey}&${userSessionParam}`),
  listTeamBoard: (sUserID: string): Promise<any> => request.get(`/wrf/GetTeamBoard?a_sUserID=${sUserID}`),
  listTeamBoardActivities: (sUserID: string): Promise<any> => request.get(`/wrf/GetTeamBoardActivities?a_sUserID=${sUserID}`),
  wrfStatusAchieved: (lWrfNo: number, bProcessId: Number, sStatus: string) => request.get(`/wrf/GetWRFApprovedStatus?a_lWrfNo=${lWrfNo}&a_bProcessID=${bProcessId}&a_sStatus=${sStatus}`),
  wrfStatusDate: (lWrfNo: number, bProcessId: Number, sStatus: string) => request.get(`/wrf/GetWRFStatusDate?a_lWrfNo=${lWrfNo}&a_bProcessID=${bProcessId}&a_sStatus=${sStatus}`),
  getProcessId: (sRequestTypeID: string) => request.get(`/wrf/GetProcessID?a_sRequestTypeID=${sRequestTypeID}`),
  processInstanceLogByStatus: (sProcessStatusId: string, iWrfNo: number, bProcessId: Number) => request.get(`/wrf/GetProcessInstanceLogByStatus?a_sProcessStatusId=${sProcessStatusId}&a_lWrfNo=${iWrfNo}&a_bProcessID=${bProcessId}`),
  listProcessInstanceLog: (iWrfNo: number, bProcessId: Number): Promise<any> => request.get(`/wrf/GetWRFAllInstanceLogs?a_lWrfNo=${iWrfNo}&a_bProcessID=${bProcessId}`),
  listEmailLog: (sEntityId: string, sWrfId: string): Promise<any> => request.get(`/wrf/GetWRFAllEmailLogs?a_sEntityId=${sEntityId}&a_sWrfID=${sWrfId}`),
  details: (sRequestNo: number, sEntityId: string, sApplicationId: string, sRequestTypeId: string, iYear: number) => request.get(`/wrf/GetWRFDetailsByID?a_iRequestNo=${sRequestNo}&a_sEntityID=${sEntityId}&a_sApplicationID=${sApplicationId}&a_sRequestTypeID=${sRequestTypeId}&a_iYear=${iYear}`),
  create: (wrf: IWrf, userSessionParam: string) => request.post(`/wrf/Create?${userSessionParam}`, wrf),
  update: (wrf: IWrf, userSessionParam: string) => request.post(`/wrf/Update?${userSessionParam}`, wrf),
  listWRFStats: (userSessionParam: string): Promise<any> => request.get(`/wrf/GetAllByWRFStats?a_sObjType=WRF&${userSessionParam}`),
  updateWRFState: (action: IWRFAction, userSessionParam: string) => activityRequest.post(`/wrf/UpdateWRFState?${userSessionParam}`, action), 
  detailByWrfNo: (lWrfNo: number): Promise<any> => request.get(`/wrf/GetWRFDetailsByWrfNo?a_lWrfno=${lWrfNo}`),  
  createLinkedWrf: (wrf: IWrf, userSessionParam: string) => activityRequest.post(`/wrf/Create?${userSessionParam}`, wrf),
};

export default wrfService;
