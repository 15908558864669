import { toast } from "react-toastify";
import moment, { months } from "moment";
var CryptoJS = require("crypto-js");

class GlobalsUtils {

  modeImg(img: string) {
    let imgpath = "";
    if (img) {
      imgpath = "/assets/img/EditIcn.gif";
    }
    else {
      imgpath = "/assets/img/AddIcn.gif";
    }
    return imgpath;
  }

  modeScreen(id: string) {
    let alttxt = "";
    if (id) {
      alttxt = "Edit Mode";
    }
    else {
      alttxt = "Add New Mode";
    }
    return alttxt;
  }

  convertToTimeZone = (sDate: string): string => {
    let tempDate;
    let sDates = "";
    let sDatePart: any;
    let sTimePart: any;
    let tempDatePart: any;
    let bdateIsValid: boolean;

    sDates = (sDate?moment(sDate).format('YYYY-MM-DD HH:mm:ss'):undefined!);
    bdateIsValid = moment(sDates, "'YYYY-MM-DD HH:mm:ss'").isValid();

    if (!bdateIsValid && sDates!==undefined) {
      if (sDate.includes(",")) {
        tempDate = sDate.split(",");
        sDatePart = tempDate[0];
        sTimePart = tempDate[1];

        if (sDatePart.includes('/')) {
          tempDatePart = sDatePart.split("/");
        }
        else if (sDatePart.includes('-')) {
          tempDatePart = sDatePart.split("-");
        }
        else if (sDatePart.includes('.')) {
          tempDatePart = sDatePart.split("/");
        }
        sDates = tempDatePart[2] + "-" + tempDatePart[1] + "-" + tempDatePart[0]
        sDates = sDates + "T" + sTimePart;
      }
      else if (sDates.includes("T")) {
        tempDate = sDate.split("T");
        sDatePart = tempDate[0];
        sTimePart = tempDate[1];

        if (sDatePart.includes('/')) {
          tempDatePart = sDatePart.split("/");
        }
        else if (sDatePart.includes('-')) {
          tempDatePart = sDatePart.split("-");
        }
        else if (sDatePart.includes('.')) {
          tempDatePart = sDatePart.split("/");
        }
        sDates = tempDatePart[2] + "-" + tempDatePart[1] + "-" + tempDatePart[0]
        sDates = sDates + "T" + sTimePart;
      }
      else {
        tempDate = sDate.split(" ");
        sDatePart = tempDate[0];
        sTimePart = tempDate[1];

        if (sDatePart.includes('/')) {
          tempDatePart = sDatePart.split("/");
        }
        else if (sDatePart.includes('-')) {
          tempDatePart = sDatePart.split("-");
        }
        else if (sDatePart.includes('.')) {
          tempDatePart = sDatePart.split("/");
        }
        sDates = tempDatePart[2] + "-" + tempDatePart[1] + "-" + tempDatePart[0]
        sDates = sDates + "T" + sTimePart;
      }
    }
    else
    {
      if (sDates !== undefined) {
      if (sDates.includes(",")) {
        tempDate = sDates.split(",");
        sDatePart = tempDate[0];
        sTimePart = tempDate[1];
        sDates = sDatePart + "T" + sTimePart;
      }
      if (sDates.includes("T")) {
        tempDate = sDates.split("T");
        sDatePart = tempDate[0];
        sTimePart = tempDate[1];
        sDates = sDatePart + "T" + sTimePart;
      }
      else {
        tempDate = sDates.split(" ");
        sDatePart = tempDate[0];
        sTimePart = tempDate[1];
        sDates = sDatePart + "T" + sTimePart;
      }
    }
  }

  if (sDates !== undefined) {
    sDates = sDates.replace(/\s+/g, '');
  }
    return sDates;
  }

  setParseDateToLocalRegion = (date: string): string => {

    let sDate = "";

    if (date !== "") {
      sDate = this.convertToTimeZone(date);
    }

    return sDate;
  }
// start-For Future Use
  // getParseDateToLocalRegion = (region: string, date: string): string => {

  //   let sDate = "";
  //   let sRegionToConvertIn = region;
  //   if (region.includes("Asia")) {
  //     if (region.includes("Karachi")) {
  //       sDate = new Date(date).toLocaleString();
  //       sDate = this.convertToTimeZone(date, sRegionToConvertIn, 'en-US', '0000');
  //     }
  //   }
  //   else if (region.includes("Europe")) {
  //     if (region.includes("London")) {

  //       sDate = this.convertToTimeZone(date, sRegionToConvertIn, 'en-GB', '0000');
  //     }
  //   }
  //   else if (region.includes("Africa")) {
  //     if (region.includes("Nairobi")) {
  //       sDate = this.convertToTimeZone(date, sRegionToConvertIn, 'en-KE', '0000');
  //     }
  //   }

  //   return sDate;
  // }
  // end-For Future Use

  parseDateToLocaleString = (date: string): string => {
    return new Date(date).toLocaleString();
  };

  parseDateTimeToLocaleString = (date: string): string => {  
    let dateTime;    
    if(typeof(date) === 'undefined' || date === "1900-01-01T00:00:00" || date === "")
    {
      return dateTime = "No Date";
    }
    else{
      dateTime = moment(date).format('YYYY-MM-DD HH:mm:ss');
      return dateTime.toLocaleString();
    }
  };

  parseDateTimeForDatePicker = (dateParam: string): string => {
    let date = moment(dateParam).format('YYYY-MM-DD');
    let time = moment(dateParam).format('HH:mm:ss');
    let dateTime = date + "T" + time;
    
    return dateTime;
  };

  parseDateTimeToDateString = (date: string): string => {
    if(typeof(date) != 'undefined')
    {
      return date.split('T')[0];
    }
    else
    {
      return '';
    }
  };

  getCurrentDateToLocaleString = (): string => {
    return new Date().toLocaleString();
  };
  
  getPreviousweekDateToLocaleString = (): string => {
    var newDate = new Date(); 
    var pastDate = (newDate.getDate() - 7);
    newDate.setDate(pastDate);
    return newDate.toLocaleString();
  };

  subtract_weeks = (dt: Date, n: number): string =>{ 
    return new Date(dt.setDate(dt.getDate() - (n * 7))).toLocaleString();      
 }

  AllowUpperCaseAphabets = (value: string) => {
    const reg = new RegExp('^[A-Z]*$');
    return reg.test(value)
  }

  AllowNumberOnly = (value: string) => {
    const reg = new RegExp('^[0-9]*$');
    return reg.test(value)
  }

  ConvertToUpper = (value: string) => {
    const reg = new RegExp('^[a-zA-Z]*$');
    if (reg.test(value)) {
      return value.toUpperCase();
    }
    else {
      return "";
    }
  }

  ConvertToUpperAlphabets = (value: string) => {
    const reg = new RegExp('^[a-zA-Z]*$');
    if (reg.test(value)) {
      return this.ConvertToUpper(value);
    }
    else {
      var newValue="";
      for (var i=0; i< value.length; i++)
      {
        if(reg.test(value.charAt(i)))
        {
          newValue+=this.ConvertToUpper(value.charAt(i));
        }
        else
        {
          newValue+=value.charAt(i);
        }
      }
      return newValue;
    }
  }

  ConvertToUpperwithHyphen = (value: string) => {
    const reg = new RegExp('^[a-zA-Z-]*$');
    const reg1 = new RegExp('^[a-zA-Z]*$');
    if (reg.test(value)) {
      return this.ConvertToUpperAlphabets(value);
    }
    else if (reg1.test(value)) {
      return this.ConvertToUpperAlphabets(value);
    }
    else {
      return "";
    }
  }
  
  AllowAphabets = (value: string) => {
    const reg = new RegExp('^[a-zA-Z]*$');
    return reg.test(value)
  }

  AllowAphabetsWithHyphen = (value: string, checktype?: number) => {
    const reg = new RegExp('^([a-zA-Z])+([-]?[a-zA-Z]+)*$');
    const reg1 = new RegExp('^[a-zA-Z-]*$');
    if(checktype === 0) //OnChange
    {
      return (reg.test(value) || reg1.test(value));
    }
    else if(checktype === 1) //OnBlur
    {
      return (reg.test(value));
    }
  }
  
  AllowPositiveVauesOnly = (value: string) => {
    const reg = new RegExp('^[0-9]*$');
    return reg.test(value)
  }

  AllowAphabetsAndNumbers = (value: string) => {
    const reg = new RegExp('^[1-zA-Z 0-9]*$');
    if (reg.test(value)) {
      return value.toUpperCase();
    }
    else {
      return "";
    }
  }

  showMessge = (msgtype: number, msg: string, timeout: number = 10000) => {
    if (msgtype == 1) {
      toast.success(msg, { position: "top-center", autoClose: timeout });
    }
    else if (msgtype == 2) {
      toast.error(msg, { position: "top-center", autoClose: timeout });
    }
    else if (msgtype == 3) {
      toast.warn(msg, { position: "top-center", autoClose: timeout });
    }
    else if (msgtype == 4) {
      toast.info(msg, { position: "top-center", autoClose: timeout });
    }
  }

  parseDate = (date: string): string => {
    let Date = moment(date).format('YYYY-MM-DD');
    return Date;
  };
  
  getRequestTypeShortForm = (requestTypeId: string): string => {
    switch (requestTypeId) {
      case 'CRF':
        return 'C';
      case 'SRF':
        return 'S';
      case 'SUPP':
        return 'U';
      default:
        return '';
    }
  }

  datediff(firstDate: Date, secondDate:  Date) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    var diff = Math.abs(firstDate.getTime() - secondDate.getTime());
    var diffDays = Math.ceil(diff / (1000 * 3600 * 24)); 
    return diffDays;
  }

  addWRFNumberLeadingZero = (requestNo: string): string => {
    return requestNo.toString().padStart(4, '0');
  }

  filterCaseInsensitiveIncludes =  (filter: any, row: any) => {
    const id = filter.pivotId || filter.id;
    return (
        row[id] !== undefined ?
            String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) :           
            true
    );
  };
  
  decrypt = (data: any, key: string) => {
    key = key.substring(2, key.length - 2);
    key = atob(key);
    key = key.substring(3, key.length - 2); 
    key = key.substring(0, 7) + key.substring(9, key.length);
    let base64 = CryptoJS.enc.Utf8.parse(key);
    let decryptedData = CryptoJS.TripleDES.decrypt(data, base64, {     
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }
    );              
    return decryptedData.toString(CryptoJS.enc.Utf8);
  };

  encrypt = (data: any, key: string) => {  
    key = key.substring(2, key.length - 2);      
    key = atob(key);                
    key = key.substring(3, key.length - 2);         
    key = key.substring(0, 7) + key.substring(9, key.length);
    let base64 = CryptoJS.enc.Utf8.parse(key);
    let encryptedData = CryptoJS.TripleDES.encrypt(data, base64, {                              
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }
    );  
    
    return encryptedData.toString();;
  }

  ParameterToEncode = (value: string) => {    
    const EncodedParameter = encodeURIComponent(value);    
    
    return EncodedParameter;
  }

  ParameterToDecode = (value: string) => {    
    const DecodedParameter = decodeURIComponent(value);        
    
    return DecodedParameter;
  }   
}
export default new GlobalsUtils();
  
