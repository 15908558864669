import React from "react";
import Button from  "reactstrap-button-loader";
import Modal from 'react-bootstrap/Modal';
import "../../assets/scss/style.scss";
import moment from "moment";
import GlobalUtils from '../../utils/Globals';

export const Modals = (props: any) => {

  return (
    <div className="row col-sm-12">
    <Modal size={props.size} className="row" show={props.show} onHide={props.close} centered>
      <Modal.Header style={{backgroundColor:"#00BFFF",color:"white",border:"0px solid"}} closeButton>
        <Modal.Title style={{color:"white",fontSize:"18px"}}>{props.title}&nbsp;<i className={props.icon}></i></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.confirmActivities}</p>
        {props.body}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.close}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    </div>
  );
}

export const responseModals = (props: any) => {

  return (
    <div className="row col-sm-12">
    <Modal size={props.size} className="row" show={props.show} onHide={props.close} centered>
      <Modal.Header style={{backgroundColor:"#00BFFF",color:"white",border:"0px solid"}} closeButton>
        <Modal.Title style={{color:"white",fontSize:"18px"}}>{props.title}&nbsp;<i className={props.icon}></i></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.confirmActivities}</p>
        {props.body}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.close}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    </div>
  );
}

export const activityModals = (props: any) => {

  return (
    <div className="row col-sm-12">
    <Modal size={props.size} className="row" show={props.show} onHide={props.close} centered>
      <Modal.Header style={{backgroundColor:"#404040",color:"white",border:"0px solid"}} closeButton>
          <Modal.Title><strong style={{ fontSize: "18px", color: "white" }}>{props.title}&nbsp;<img style={{ height: "40px", width: "50px" }} src={props.modeImage} alt=""/>
      <span {...props.wrfNo === 0 ? { hidden: true } : { hidden: false }} style={{ marginLeft: "0px" }} className="vertical-line"></span>
      <span {...props.wrfNo === 0 ? { hidden: true } : { hidden: false }} className="badge" style={{ marginLeft: "0px", fontSize: "12px", marginTop: "5px", color: "white", backgroundColor: "#62605F" }}>{props.applicationId + "-" + props.requestTypeId.slice(0, 1) + moment(props.requestDate).format("YYYY") + "-" + GlobalUtils.addWRFNumberLeadingZero(props.requestNo.toString())}</span></strong></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.confirmActivities}</p>
        {props.body}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.close}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    </div>
  );
}

export const activityTimeLogModals = (props: any) => {
  return (
    <div className="row col-sm-12">
      <Modal size={props.size} className="row" show={props.show} onHide={props.close} centered>
        <Modal.Body>
          {props.body}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.close}>
            Close
        </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export const activityProgramModals = (props: any) => {
  return (
    <div className="row col-sm-12">
      <Modal size={props.size} className="row" show={props.show} onHide={props.close} centered>
        <Modal.Body>
          {props.body}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.close}>
            Close
        </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export const inputModal = (props: any) => {
  return (
    <div className="container">
      <Modal className="modal" id="genericModal" show={props.show} onHide={props.handleClose} centered>
        <Modal.Header style={{ backgroundColor: "#00BFFF", color: "white" }} closeButton>
          <Modal.Title>{props.header}&nbsp;<i className="fa fa-file"></i></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.body}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export const myQueuesActivityModals = (props: any) => {
  return (
    <div className="row col-sm-12">
      <Modal size={props.size} className="row" show={props.show} onHide={props.close} centered>
        <Modal.Body>
          {props.body}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.close}>
            Close
        </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export const myQueuesModals = (props: any) => {
  return (
    <div className="row col-sm-12">
      <Modal size={props.size} className="row" show={props.show} onHide={props.close} centered>
        <Modal.Header style={{ backgroundColor: "#404040", color: "white" }} closeButton>
          <Modal.Title>{props.header}&nbsp;<i className={props.headerIcon}></i></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.body}
        </Modal.Body>
          <Modal.Footer>
           <Button variant="secondary" onClick={props.close}>
            Close
           </Button>
          </Modal.Footer>
      </Modal>
    </div>
  );
}

export const loginScreenModals = (props: any) => {
  return (
    <div className="row col-sm-12">
      <Modal size={props.size} className="row" show={props.show} onHide={props.close} centered>
        <Modal.Header closeButton style={{ color: "white", textAlign: "center", backgroundColor: "#fc710d", fontSize: "20px" }}>
          <Modal.Title id="forgotPasswordModalTitle">{props.props.heading}&nbsp;<i className={props.icon}></i></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.body}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.close}>
            Close
           </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export const HTMLModals = (props: any) => {
  return (
    <div className="row col-sm-12">
      <Modal size={props.size} className="row" show={props.show} onHide={props.close} centered>
        <Modal.Header style={{ backgroundColor: "#00BFFF", color: "white" }} closeButton>
          <Modal.Title>{props.header}&nbsp;<i className={props.headerIcon}></i></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {props.body} */}
          {"Coming Soon!"}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.close}>
            Close
           </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export const myQueuesActivitiesStatusValidationModals = (props: any) => {
  return (
    <div className="row col-sm-12">
      <Modal size={props.size} className="row" show={props.show} onHide={props.close} centered>
        <Modal.Header style={{ backgroundColor: "#00BFFF", color: "white" }} closeButton>
          <Modal.Title>{props.title}&nbsp;<i className={props.headerIcon}></i></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.body}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.close}>
            Close
           </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export const fileAttachmentModals = (props: any) => {
  return (
    <div className="row col-sm-12">
      <Modal size={props.size} className="row" show={props.show} onHide={props.close} centered>
        <Modal.Header style={{ backgroundColor: "#00BFFF", color: "white" }} closeButton>
          <Modal.Title>{props.title}&nbsp;<i className={props.headerIcon}></i></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.body}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.close}>
            Close
           </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export const customModal = (props: any) => {
  return (
    <div id="customModalBox" className="customModalBoxOverlay customModalBoxFade-In">
      <div className="customModalBoxBody">
        <div className="customModalBoxHeader"><i className={props.headerIcon}></i>&nbsp;Information (auto-close)</div>        
        <div className="content">
          <p>{props.message}</p>
        </div>
      </div>
    </div>
  );
}

export const supervisorBoardModals = (props: any) => {
  return (
    <div className="row col-sm-12">
      <Modal size={props.size} className="row" show={props.show} onHide={props.close} centered>
        <Modal.Header closeButton style={{ color: "white", textAlign: "center", backgroundColor: props.headercolor, fontSize: "20px" }}>
          <Modal.Title id="forgotPasswordModalTitle">{props.title}&nbsp;<i className={props.icon}></i></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.body}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.close}>
            Close
           </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export const WRFDetailModals = (props: any) => {
  return (
    <div className="row col-sm-12">
      <Modal size={props.size} className="row" show={props.show} onHide={props.close} centered>
        <Modal.Header closeButton style={{ color: "white", textAlign: "center", backgroundColor: props.headercolor, fontSize: "20px" }}>
          <Modal.Title id="forgotPasswordModalTitle">{props.title}&nbsp;<i className={props.icon}></i></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.body}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.close}>
            Close
           </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}



