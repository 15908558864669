import axios, { AxiosResponse, AxiosError } from "axios";
import { toast } from "react-toastify";
import { AppConstants } from "../utils";
import 'react-toastify/dist/ReactToastify.css';
axios.defaults.baseURL = AppConstants.SERVER_URL;

interface IResponseBody {
  success: boolean;
  data?: {};
  status?: string;
  message?: string;
}

const responseBody = (response: AxiosResponse<IResponseBody>) => {
  return {
    success: true,
    data: response.data,
    status: response.status,
    message: ""
  };
};

const errorBody = (error: AxiosError) => {
  return {
    success: false,
    status: error.code,
    message: error.message
  };
};

const request = {
  get: (url: string) =>
    axios
      .get(url)
      .then(responseBody)
      .catch(errorBody),
  post: (url: string, body: {}, headers?: {}) =>
    axios
      .post(url, body)
      .then(function (response) {
        let notify
        if (response.data.success && response.data.message.length > 0) {
          notify = () => toast.success(response.data.message, { position: "top-center" });
        }
        else if (!response.data.success && response.data.message.length > 0) {
          notify = () => toast.error(response.data.message, { position: "top-center" });
        }
        else {
          notify = () => null;
        }
        notify();
        return response.data.success;
      })
      .catch(errorBody),
  put: (url: string, body: {}) =>
    axios
      .put(url, body)
      .then(responseBody)
      .catch(errorBody),
  delete: (url: string) =>
    axios
      .delete(url)
      .then(responseBody)
      .catch(errorBody)
};

const activityRequest = {
  post: (url: string, body: {}, headers?: {}) =>
    axios
      .post(url, body)
      .then(function (response) {
        return response;
      })
      .catch(errorBody)
}

const fileAttachmentRequest = {
  post: (url: string, body: {}, headers?: {}) =>
    axios
      .post(url, body, headers)
      .then(function (response) {
        //Start-For Later Use
        // let notify
        // if (response.data.success && response.data.message.length > 0 && response.data.message !== "FileOK") {
        //   notify = () => toast.success(response.data.message, { position: "top-center" });
        // }
        // else if (!response.data.success && response.data.message.length > 0) {
        //   notify = () => toast.error(response.data.message, { position: "top-center" });
        // }
        // else {
        //   notify = () => null;
        // }
        // notify();
        // return response.data.success;
        //End-For Later Use
        return response;
      })
      .catch(errorBody)
}

export {request, activityRequest, fileAttachmentRequest};
